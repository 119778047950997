// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-templates-game-page-game-page-tsx": () => import("./../../../src/templates/GamePage/GamePage.tsx" /* webpackChunkName: "component---src-templates-game-page-game-page-tsx" */),
  "component---src-templates-home-page-home-page-tsx": () => import("./../../../src/templates/HomePage/HomePage.tsx" /* webpackChunkName: "component---src-templates-home-page-home-page-tsx" */),
  "component---src-templates-podcast-category-page-podcast-category-page-tsx": () => import("./../../../src/templates/PodcastCategoryPage/PodcastCategoryPage.tsx" /* webpackChunkName: "component---src-templates-podcast-category-page-podcast-category-page-tsx" */),
  "component---src-templates-podcast-post-page-podcast-post-page-tsx": () => import("./../../../src/templates/PodcastPostPage/PodcastPostPage.tsx" /* webpackChunkName: "component---src-templates-podcast-post-page-podcast-post-page-tsx" */),
  "component---src-templates-video-category-page-video-category-page-tsx": () => import("./../../../src/templates/VideoCategoryPage/VideoCategoryPage.tsx" /* webpackChunkName: "component---src-templates-video-category-page-video-category-page-tsx" */),
  "component---src-templates-video-post-page-video-post-page-tsx": () => import("./../../../src/templates/VideoPostPage/VideoPostPage.tsx" /* webpackChunkName: "component---src-templates-video-post-page-video-post-page-tsx" */)
}

